import Axios from 'agilite-utils/axios'
import { toDate } from 'agilite-utils/date-fns'
import { formatRecordData } from '../utils/utilities'
import MemoryStore from '../utils/memory-store'
import BatchLoggingFormContainer from '../batch-logging/containers/batch-logging-form-container'
import Enums from '../utils/enums'
import Config from '../utils/config.json'

export const filterViewData = (viewData, searchFilter) => {
  searchFilter = searchFilter.toLowerCase()

  if (searchFilter === '') {
    return viewData
  } else {
    return viewData.filter(t =>
      (
        (t.data.name.toLowerCase().indexOf(searchFilter) > -1) ||
        (t.data.key.toLowerCase().indexOf(searchFilter) > -1) ||
        (t.data.description.toLowerCase().indexOf(searchFilter) > -1)
      )
    )
  }
}

export const filterLogViewData = (viewData, searchFilter) => {
  searchFilter = searchFilter.toLowerCase()

  if (searchFilter === '') {
    return viewData
  } else {
    return viewData.filter(t =>
      (
        (t.data.response.toLowerCase().indexOf(searchFilter) > -1) ||
        (t.data.moduleName.toLowerCase().indexOf(searchFilter) > -1) ||
        (t.data.uri.toLowerCase().indexOf(searchFilter) > -1) ||
        (t.data.method.toLowerCase().indexOf(searchFilter) > -1) ||
        (t._id.toLowerCase().indexOf(searchFilter) > -1)
      )
    )
  }
}

export const formatRecord = (entry, data) => {
  let tempIndex = 0

  entry.key = entry._id // Needed for View's Unique key requirement
  entry = formatRecordData(entry)
  entry.data.isActiveDescription = entry.data.isActive ? 'Yes' : 'No' // Needed for View Column

  if (entry.custom.isNewDoc) {
    // Add new Entry to State
    entry.custom.isNewDoc = false
    entry.custom.action = 'create'
    data.push(entry)
  } else {
    if (entry.custom.isSavedDoc) {
      entry.custom.action = 'update'
    } else {
      entry.custom.action = 'create'
    }

    // Update Entry in State
    tempIndex = data.findIndex(t => t._id === entry._id)
    data[tempIndex] = entry
  }

  return data
}

export const createCopy = (currEntryData, callback) => {
  const newEntry = JSON.parse(JSON.stringify(MemoryStore.batchlogging.dataTemplate))
  newEntry._id = new Date().getTime().toString()
  newEntry.custom.tempId = newEntry._id

  const entryData = JSON.parse(JSON.stringify(currEntryData))
  const tabType = Enums.VALUES_STRINGS.FORM
  const content = BatchLoggingFormContainer
  const closable = true
  const key = newEntry._id
  const app = Enums.APP_IDS.BATCH_LOGGING

  let title = Enums.APP_PROFILE_TITLES.batchlogging + Enums.VALUES_STRINGS.COLON + Enums.VALUES_STRINGS.SPACE
  let payload = {}

  // create unique key
  entryData.key = currEntryData.key + '_copy'
  entryData.name = currEntryData.name + ' (Copy)'

  newEntry.data = entryData

  title += entryData.key

  payload = {
    content,
    title,
    key,
    app,
    tabType,
    closable
  }

  MemoryStore.activeEntries[newEntry._id] = newEntry

  callback(payload)
}

export const getLogs = (profileKey, date, includeResolvedLogs, page, pageLimit) => {
  let response = null

  return new Promise((resolve, reject) => {
    (async () => {
      const qry = {}
      let tmpStartDate = null
      let tmpEndDate = null
      let startDate = date[0].format('YYYY-MM-DD')
      let endDate = date[1].format('YYYY-MM-DD')

      try {
        if (startDate && endDate) {
          qry.createdAt = {}

          startDate += ' 00:00:00.000'
          startDate = new Date(startDate)
          tmpStartDate = toDate(startDate, { timeZone: 'UTC' })
          qry.createdAt.$gte = tmpStartDate

          endDate += ' 23:59:59.999'
          endDate = new Date(endDate)
          tmpEndDate = toDate(endDate, { timeZone: 'UTC' })
          qry.createdAt.$lte = tmpEndDate
        }

        if (includeResolvedLogs === false) {
          qry['data.resolvedDate'] = {
            $eq: null
          }
        }

        response = await MemoryStore.agilite.BatchLogging.getLogs(profileKey, JSON.stringify(qry), null, JSON.stringify({ sort: '-createdAt' }), page, pageLimit)
        resolve(response.data)
      } catch (error) {
        if (error.response?.data.errorMessage) {
          reject(error.response.data.errorMessage)
        } else if (error.message) {
          reject(error.message)
        } else {
          reject(Enums.MESSAGES.UNKNOWN_ERROR)
        }
      }
    })()
  })
}

export const countLogs = (profileKey, date, includeResolvedLogs) => {
  let response = null

  return new Promise((resolve, reject) => {
    (async () => {
      const qry = {}
      let tmpStartDate = null
      let tmpEndDate = null
      let startDate = date[0].format('YYYY-MM-DD')
      let endDate = date[1].format('YYYY-MM-DD')

      try {
        if (startDate && endDate) {
          qry.createdAt = {}

          startDate += ' 00:00:00.000'
          startDate = new Date(startDate)
          tmpStartDate = toDate(startDate, { timeZone: 'UTC' })
          qry.createdAt.$gte = tmpStartDate

          endDate += ' 23:59:59.999'
          endDate = new Date(endDate)
          tmpEndDate = toDate(endDate, { timeZone: 'UTC' })
          qry.createdAt.$lte = tmpEndDate
        }

        if (includeResolvedLogs === false) {
          qry['data.resolvedDate'] = {
            $eq: null
          }
        }

        response = await MemoryStore.agilite.BatchLogging.countLogs(profileKey, JSON.stringify(qry))
        resolve(response.data.result)
      } catch (error) {
        if (error.response?.data.errorMessage) {
          reject(error.response.data.errorMessage)
        } else if (error.message) {
          reject(error.message)
        } else {
          reject(Enums.MESSAGES.UNKNOWN_ERROR)
        }
      }
    })()
  })
}

export const resolveLog = (logIds, condition) => {
  let response = null

  return new Promise((resolve, reject) => {
    (async () => {
      try {
        response = await Axios.request(`${Config.apiServerUrl}/batchlogging/resolve`, {
          method: Enums.REQ_TYPE.POST,
          data: {
            logIds
          },
          headers: {
            [Enums.HEADER_PROPS.API_KEY]: MemoryStore.apiKey,
            condition
          }
        })
        resolve(response.data)
      } catch (error) {
        if (error.response?.data.errorMessage) {
          reject(error.response.data.errorMessage)
        } else if (error.message) {
          reject(error.message)
        } else {
          reject(Enums.MESSAGES.UNKNOWN_ERROR)
        }
      }
    })()
  })
}

export const unresolveLog = (logIds, condition) => {
  let response = null

  return new Promise((resolve, reject) => {
    (async () => {
      try {
        response = await Axios.request(`${Config.apiServerUrl}/batchlogging/unresolve`, {
          method: Enums.REQ_TYPE.POST,
          data: {
            logIds
          },
          headers: {
            [Enums.HEADER_PROPS.API_KEY]: MemoryStore.apiKey,
            condition
          }
        })
        resolve(response.data)
      } catch (error) {
        if (error.response?.data.errorMessage) {
          reject(error.response.data.errorMessage)
        } else if (error.message) {
          reject(error.message)
        } else {
          reject(Enums.MESSAGES.UNKNOWN_ERROR)
        }
      }
    })()
  })
}

export const resetLogs = (profileKey) => {
  return new Promise((resolve, reject) => {
    (async () => {
      try {
        await MemoryStore.agilite.BatchLogging.resetLogs(profileKey)
        resolve()
      } catch (error) {
        if (error.response?.data.errorMessage) {
          reject(error.response.data.errorMessage)
        } else if (error.message) {
          reject(error.message)
        } else {
          reject(Enums.MESSAGES.UNKNOWN_ERROR)
        }
      }
    })()
  })
}

export const getLogFilterValues = (profileKey) => {
  let response = null

  return new Promise((resolve, reject) => {
    (async () => {
      try {
        response = await MemoryStore.agilite.BatchLogging.getLogFilterValues(profileKey)
        resolve(response.data)
      } catch (error) {
        if (error.response?.data.errorMessage) {
          reject(error.response.data.errorMessage)
        } else if (error.message) {
          reject(error.message)
        } else {
          reject(Enums.MESSAGES.UNKNOWN_ERROR)
        }
      }
    })()
  })
}
